'use strict';
var linkCss = document.createElement("link");
linkCss.rel = "stylesheet";
linkCss.href = "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700";
var siteHeadElem = document.head || document.getElementsByTagName("head")[0];
siteHeadElem.appendChild(linkCss);
'use strict';

var topBar = {

	init: function(){
    this.hamburgerMenu();
    this.searchOverlay();
	},

  //Hamburger Menu
  hamburgerMenu: function () {
    $('.c-hamburger').click(function (event) {
      event.stopPropagation();
      $(this).toggleClass('is-active');
      if ($(this).hasClass('is-active')) {
        $('.overlayMenuContainer').fadeIn("slow");
        var menuContainerHeight = $(".overlayMenuContainer__container").height();
        var liCount = $(".overlayMenuContainer__container ul li").length;
        $(".overlayMenuContainer__container ul li").each(function (i, obj) {
          $(obj).css("transition-delay", (100 * i) + "ms");
          $(obj).removeClass("introOut");
          $(obj).addClass("intro");
          var myHeight = $(obj).height();
          myPadding = ((menuContainerHeight - 80 - (myHeight * liCount)) / liCount) / 2;
          $(obj).css('padding-top', myPadding);
          $(obj).css('padding-bottom', myPadding);
        });
      } else {
        $('.overlayMenuContainer').fadeOut("slow");
        $(".overlayMenuContainer__container ul li").each(function (i, obj) {
          $(obj).css("transition-delay", (100 * i) + "ms");
          $(obj).removeClass("intro");
          $(obj).addClass("introOut");
        });
      }
    });
  },

  //Overlay Search
  searchOverlay: function () {
    $('.menu-bar__search').click(function (event) {
      event.stopPropagation();
      $(this).toggleClass('is-active');
      if ($(this).hasClass('is-active')) {
        $('.searchOverlay').fadeIn("slow");
      } else {
        $('.searchOverlay').fadeOut("slow");
      }
    });
  }

}
'use strict';

var sliderLoghi = {

	init: function(){
		this.slickSlider();
	},

  slickSlider:function(){
    $('.box-loghi__slider').slick({
      dots: false,
      arrow: false,
      infinite: true,
      speed: 500,
      cssEase: 'linear',
      pauseOnHover: false,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        }
      ]
    });
  }
	
}
'use strict';

var filters = {

	init: function(){
		this.dependentFilterProductsInit();
	},

  dependentFilterProductsInit: function() {
    var $form = $('#productFilter form');
    var $formWrap = $('#productFilter');
    var $filters = $form.find('select');
    $filters.select2();
    $filters.change(function () {
      $.ajax({
        type: 'GET',
        url: '/assets/site_parts/common/filters.php',
        data: $form.serialize() + '&update-filters=1',
        beforeSend: function () {
          $('<div class="loadingOver"></div>').prependTo($formWrap);
        },
        success: function (results) {
          $form.remove();
          $formWrap.append(results);
          filters.init();
          $('.loadingOver', $formWrap).fadeOut(100, function () {
            $(this).remove();
          });
        }
      });
    });
  }
	
}
'use strict';

var eventBox = {

	init: function(){
    if ($('#eventBox').length > 0) {
      setTimeout(function () {
        $('#eventBox').fadeIn('slow');
      }, 1500);
      this.eventBoxClose();
    }
	},

  eventBoxClose: function(){
    $('.eventBox__close').click(function(){
      $('#eventBox').fadeOut();
      var sessionId = $('#eventBox').data('session');
      eventBox.setCookie(sessionId);
    });
  },

  setCookie: function(id){
    var d = new Date();
    d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = "mm_event_"+ id + "= 1;" + expires + ";path=/";
  }
	
}
'use strict';

var gallery = {

	init: function(){
		this.slickSlider();
	},

  slickSlider:function(){
    /*$('.custom-gallery__content').slick({
      dots: false,
      infinite: true,
      speed: 500,
      cssEase: 'linear',
      pauseOnHover: false
    });*/

    $('.prod-detail__gallery').slick({
      dots: false,
      infinite: true,
      speed: 500,
      cssEase: 'linear',
      pauseOnHover: false
    });
  }
	
}
'use strict';

var product = {

	init: function(){
    this.slickSlider();
	},

  slickSlider: function () {
    if($('.product').length>4){
      $('.product__related').slick({
        dots: false,
        infinite: true,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        speed: 500,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 2,
              autoplay: true,
              autoplaySpeed: 8000
            }
          }
        ]
      });
    }
  }
}
var barbaJs = {
	init: function(){
		Barba.Pjax.start();
		Barba.Dispatcher.on('transitionCompleted', function() {
			
			$('html, body').scrollTop(0);
			mm_track_pageview(location.pathname);
			siteGlobal.rebuildAllEvents();

			//custom cookie bar
			if($('.cookie-bar').is(":hidden")) {
				$('.cookie-bar').show();
			}

		});

		Barba.Dispatcher.on('newPageReady',function(currentStatus, oldStatus, container){
			var js = container.querySelectorAll("script");
			if(js != null && js.length>0){
				for(i in js){
					if(js[i].innerHTML!=='') eval(js[i].innerHTML);
					if(js[i].src!=='') $.getScript(js[i].src);
				}
			}
		});

		Barba.Dispatcher.on('linkClicked', function() {
			/*console.log(barbaJs.getCookie('privacyCookie'));
			if (barbaJs.getCookie('privacyCookie') == null) {
				barbaJs.setCookie('privacyCookie', 'C01,C02,C03', 10 * 365);
			}*/
		});

		// Barba Google Analytics
		Barba.Dispatcher.on('initStateChange', function () { 
			if (typeof ga !== 'function' || Barba.HistoryManager.history.length <= 1) {
				return;
			}
		
			ga('send', 'pageview', window.location.pathname);
		});
		

		Barba.Pjax.getTransition = function() {
			return FadeTransition;
		};

		Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;
		
		Barba.Pjax.preventCheck = function(evt, element) {
			if (!Barba.Pjax.originalPreventCheck(evt, element)) {
				return false;
				alert('');
			}
			// No need to check for element.href -
			// originalPreventCheck does this for us! (and more!)
			if (/.pdf/.test(element.href.toLowerCase())) {
				return false;
			}
			return true;
		};
		
	},

	getCookie:function(name) {
		var re = new RegExp(name + "=([^;]+)");
		var value = re.exec(document.cookie);
		return (value != null) ? unescape(value[1]) : null;
	},

	setCookie:function(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}
}

FadeTransition = Barba.BaseTransition.extend({
	start: function() {
		Promise.all([this.newContainerLoading, this.fadeOut()]).then(this.fadeIn.bind(this));
	},
	fadeOut: function() {
		return $(this.oldContainer).animate({ opacity: 0 }).promise();
	},

	fadeIn: function() {
		var _this = this;
		var $el = $(this.newContainer);

		$(this.oldContainer).hide();

		$el.css({
			visibility : 'visible',
			opacity : 0
		});

		$el.animate({ opacity: 1 }, 400, function() {
			_this.done();
		});
	}
});
'use strict';
var siteGlobal = {

  options : {
    breakpoints: { 480: 'xxs',768: 'xs', 980: 'sm', 1200: 'md', 5000: 'lg' },
  },

  defaultScrollOffset:0,

  initReady: function () {
    this.breakPointUtility();
    this.goToPosOffsetCalc();
    this.linkAll();
    this.linkParts();
    topBar.init();
    sliderLoghi.init();
    eventBox.init();
    gallery.init();
    product.init();
  },

  initLoad: function () {
    this.goToPosOffsetCalc();
    this.breakPointUtility();
    setTimeout(function(){
      lazyLoad.init();
    },150);
    this.gmapPageInit();
    $('.theme__mh').matchHeight();
    filters.init();
    resArea.init();
    //customCookie.init();
  },

  initFirstLoad: function(){
    barbaJs.init();
    this.overlayAllOff();
  },

  rebuildAllEvents: function(){
    this.initReady();
    this.initLoad();
  },

  initResize: function(){
    this.breakPointUtility();
  },

  initResizeEnd: function(){
    this.goToPosOffsetCalc();
  },

  initScroll: function(){
  },


  /**
   * OPEN MODAL (Usata di solito per social)
   * @param  {string} url : url pagina da aprire nella nuova finestra
   */
  openModal:function(url) {
    url = decodeURIComponent(((url) + '').replace(/\+/g, '%20'));
    window.open(url, 'socials', 'height=450,width=880,toolbar=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,modal=yes');
  },


  /**
   * ELEMENTI INTERAMENTE LINKABILI
   */
  linkAll: function(){
    $('.linkFrom').each(function() { 
        var elemToLink = $(this).parents('.linkTo');
        var pageLink = $(this).attr('href');
        var linkTarget = (typeof $(this).attr('target')!=='undefined') ?  $(this).attr('target') : '' ;
        var nobarba = $(this).hasClass('no-barba');
      if(pageLink !== undefined && pageLink!=='javascript:;') {
        elemToLink.addClass('pointer').click({myLink:pageLink, myTarget:linkTarget, nobarba:nobarba},function(e){
          e.preventDefault();
          if(e.data.myTarget==='_blank'){
            window.open(e.data.myLink);
          } else {
            if(typeof barbaJs !=='undefined' && !e.data.nobarba){
              Barba.Pjax.goTo(e.data.myLink); 
            } else {
              location.href = e.data.myLink;
            }
          }
        });
      }
    }); 
  },

  /**
   * ELEMENTI PARZIALMENTE LINKABILI (aree diversificate all'interno dello stesso contenitore)
   */
  linkParts: function(){
    var elems = $('.linkFromPart');
    var elemsLg = elems.length;
    if(elemsLg>0){
      var i=0;
      for (i; i<=elemsLg-1; i++) {
        var el = $(elems[i]);
        var pageLink = el.attr('href');
        if(pageLink !== undefined) {
          var blockWrap = el.parents('.linkWrap');
          var elemsToLink = $('.linkToPart',blockWrap);
          var nobarba = $(this).hasClass('no-barba');
          elemsToLink.addClass('pointer').click({pageLink:pageLink,nobarba:nobarba},function(e){
            if(typeof barbaJs !=='undefined' && !e.data.nobarba){
              Barba.Pjax.goTo(e.data.pageLink); 
            } else {
              location.href = e.data.pageLink;
            }
          });
        }
      }
    }
  },

  /**
   * COMPORTAMENTI JS A SECONDA DELLA DIMENSIONE PAGINA
   */
  breakPointUtility: function(){

    siteGlobal.breakPoint = 'xs';

    for (var el in this.options.breakpoints){
      if (this.options.breakpoints.hasOwnProperty(el)) {
        if ( $(window).width() <= (el-1) ){
            siteGlobal.breakPoint = this.options.breakpoints[el];
            break;
        }
      }
    }      
  },

  /**
   * OVERLAY LOADER
   */
  overlayAllOff : function() {
      setTimeout(
          function(){
              $('.overlay-all').remove();;
          },
      150);   
  },

  /**
   * CALCOLA OFFSET PER ANIMAZIONE SCROLL PAGINA
   */
  goToPosOffsetCalc:function(status){
    siteGlobal.defaultScrollOffset = 0;
    
    // Elementi da considerare per l'offset (un selettore per elemento)
    // Es. var offsetElements=['#barTopWrap'];
    var offsetElements=[];
    
    if (offsetElements.length > 0) {
      for (var el in offsetElements){
        var $el = $(offsetElements[el]);
        if($el.length>0){

          // L'elemento viene considerato nel calcolo solo se è in position fixed
          var elPos = $el.css('position');
          if(elPos === 'fixed') siteGlobal.defaultScrollOffset+= $el.outerHeight(true);
        }
      }
    }

    // Gestisco il padding del body rispetto all'offset
    $('body').css({'paddingTop':siteGlobal.defaultScrollOffset});


  }, 

  /**
   * SCROLL PAGINA
   */
  goToPos: function(ref,offset){
    // Offset Default con calcolo barre menu top
    var defaultOffset = siteGlobal.defaultScrollOffset;

    if(typeof offset === 'undefined') offset = defaultOffset;
    $ref = (typeof ref==='string') ? $(ref) : ref ;
    if($(ref).length==0)
      return false;
    var myPos = $ref.offset().top-offset;
    $('html,body').animate({
      scrollTop: myPos}, 
      { duration: 600 }
    );
  },

  gmapPageInit:function(){
    if($('#gmap_jquery').length>0 && typeof mapConfig!=='undefined') {
     mapConfig.params.drag = ($(document).width() > 768) ? true : false;
     setDirection.set(
      'gmap_jquery', 
      mapConfig.config,
      mapConfig.params.zoom,
      mapConfig.params.type,
      mapConfig.params.showCursor,
      mapConfig.params.drag,
      mapConfig.params.scroll
     );
    }
  }

};





/**
 * INIZIALIZZAZIONE SITO
 */
(function () {

  siteGlobal.initReady();

  $( window ).on("load",function() {
      siteGlobal.initLoad();
      siteGlobal.initFirstLoad();
  });

  $(window).resize(function(){
    siteGlobal.initResize();
  });
  

  if(typeof $(window).resizeend === 'function'){
    $(window).resizeend(function () {
      siteGlobal.initResizeEnd();
    });
  } else {
    $(window).resize(function(){
      siteGlobal.initResizeEnd();
    });
  }

  $(window).scroll(function(){
    siteGlobal.initScroll();
  });

}());